// cSpell:disable
//
// Copyright 2019-2022 Reki LLC - All rights reserved.
// File: config-stage.ts
// Project: rekitv
//

//
// Staging config overrides.  When stage it built, this file
// is copied into src/config-env.ts, via stage.Dockerfile.
//
// At the moment staging and dev share most settings.
//

// @ts-ignore
import config from '@/config';
// @ts-ignore
import * as constants from '@/tsfiles/constants';

export function initConfigs() {
    config.runEnvironment = 'stage';
    config.domainName = 'stage.' + constants.COMPANY_DOMAIN;
}
